$color-white: #fff;
$color-light: #EFEFEF;
$color-dark: #202C33;
$color-blue-light: #8FBEDE;
$color-blue-light-400: #4D97CA;
$color-blue-light-100: #BFF0F9;
$color-green-light: #A5C79F;
$color-blue-dark: #073A4F;
$color-blue: #215F7B;

// Define your custom theme colors
$color-white: #fff;
$color-light: #EFEFEF;
$color-dark: #202C33;
$color-blue-light: #8FBEDE;
$color-blue-light-400: #4D97CA;
$color-blue-light-100: #BFF0F9;
$color-green-light: #A5C79F;
$color-green: #84b27b;
$color-blue-dark: #073A4F;
$color-blue: #215F7B;

// Set theme colors map
$theme-colors: (
        "white": $color-white,
        "light": $color-light,
        "dark": $color-dark,
        "blue-light": $color-blue-light,
        "blue-light-400": $color-blue-light-400,
        "blue-light-100": $color-blue-light-100,
        "green-light": $color-green-light,
        "green": $color-green,
        "blue-dark": $color-blue-dark,
        "blue": $color-blue
);
$blue-border: solid 3px $color-blue-light;
$box-shadow:   2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
100px 100px 80px rgba(0, 0, 0, 0.07)
;


$grid-gutter-width: 36px;
$gutter: 1rem;


@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 900

%font-body {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

%font-roboto {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

%font-display {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  //font-variant-ligatures: none;
  //-webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body {
  background-color: $color-light;
}

.jumbo{
  padding: 14rem 0 5rem 0;
  max-width: 960px;
  @include media-breakpoint-down(md){
    padding-top: 7rem;
  }
  margin: auto;
  z-index: 1;
  position: relative;
  .header--small &{
    padding: 4rem 0 4rem 0;
    @include media-breakpoint-down(md){
      padding-top: 2rem;
    }
  }
}


.button {
  border-radius: 7px;
  background-color: $color-white;
  border-bottom: $blue-border;
  padding: 1rem 3rem;
  text-decoration: none;
  //display: inline-block;
  @extend %font-body;
  font-weight: 600;
  font-size: 18px;
  color: $color-dark;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  svg{
    margin-right: .5em;
    margin-left: -.5em;
  }
  transition: all 0.1s ease;

  &:hover, &:focus{
    svg *{
      stroke: $color-blue;
    }
    //transform: scale(1.05);
    color: $color-blue;
    border-color: $color-green;
  }
}

.button-blue{
  background-color:$color-blue-light-400 ;
  color: $color-white;
  &:hover, &:focus{
    //transform: scale(1.05);
    //color: $color-dark;
    background-color:$color-blue-light;
    border-color: $color-green;
  }
}

.header {
  background-color: $color-dark;
  //min-height: 55vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  //background-image: url("background.png");
  //background-image: url("https://cdn.discordapp.com/attachments/1129338889174270003/1247924460611436726/halvehenk_A_abstract_background_with_lines_of_data_795d01e4-19b0-4f1a-a075-21533c4e6ad5.png?ex=6661cbb8&is=66607a38&hm=6ca0b905309600ebb9631d658c570411726a9cefd5f2b48272d5bab6948160df&");
  //background-position: 0% 125%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom:8rem;
  z-index: 1;
  &.header--small{
    @include media-breakpoint-down(md){
      padding-top: 1rem;
      padding-bottom:1rem;

      img{
        max-width: 175px;
      }
    }
  }

}
.header__end{
  position: absolute;
  top:100%;;
  left:0;
  width:100%;
  height: auto;
  transform: translateY(-100%);
  height:16rem;
  pointer-events: none;
  z-index: 1;
  svg{
    object-fit: cover;
    position: absolute;
    object-position: bottom;
    margin-bottom: -1px;
    vertical-align: bottom;
    width: 100%;
    bottom:-1px;
    left:0px;
  }
}

html, body {
  padding: 0;
  margin: 0;
}

.main-heading {
  color: $color-white;
  @extend %font-roboto;
  font-size: max(28px, min(4vw, 60px));
  line-height: 1.2;
  text-shadow:
  3px 3.9px 5.8px rgba(0, 0, 0, 0.202),
  10.1px 13px 19.4px  rgba(0, 0, 0, 0.298),
  45px 58px 87px  rgba(0, 0, 0, 0.5)
;
  text-transform: uppercase;
}


.block{
  background-color: $color-white;
  padding:3rem 2rem 1.75rem 2rem;
  box-shadow: $box-shadow;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: start;
  align-items: start;
  height: 100%;
  position: relative;
  border-bottom: $blue-border;
  @extend %font-body;

  *:not(p):last-child{
    margin-top: auto;
  }

  h2{
    @extend %font-display;
    margin-bottom: 0.25rem;
    color: $color-blue-light-400;
  }

  &:before{
    content:'';
    width:3rem;
    height: 0.2rem;
    background-color: $color-blue-light-400;
    position: absolute;
    top:0;
    left:0;
  }

  .button{
    margin-left: auto;
    margin-right: auto;
  }
}

.main__content{
  position: relative;
  margin-top: - 8rem;
  padding: 0 0 4rem 0;
  z-index: 2;
}

.digit{
  @extend %font-display;
  font-size: 2rem;
  line-height: 1;

  /* pysport */
  background: linear-gradient(90.41deg, #4D97CA 0.42%, #469BC7 45.58%, #469FC9 61.04%, #54A5BE 76.51%, #6FA9A8 92.66%, #91AE8A 103.39%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  position: absolute;
  top:12px;
  left:8px;
}

.main__quote{
  padding: 6rem 0;
  transform: skewY(-1.5deg);
  h2{
    @extend %font-display;
    text-align: center;
    margin-bottom: 3rem;
    opacity: 0.7;
  }
  blockquote{
    padding-left:4rem;
    position: relative;
    //display: flex;
    //flex-wrap: wrap;
    color: $color-dark;
    line-height: 1.5;
    //font-style: italic;

    &:before{
      position: absolute;
      left:0;
      content:'';
      top:0;
      width: 3rem;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='86' height='75' viewBox='0 0 86 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 43.4735L20.1769 0H37.0462L23.1538 39.823H35.0615V75H0V43.4735ZM48.9538 43.4735L69.1308 0H86L72.1077 39.823H84.0154V75H48.9538V43.4735Z' fill='url(%23paint0_linear_1155_367)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1155_367' x1='-2.91634e-07' y1='43.6732' x2='89.1862' y2='44.4111' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234D97CA'/%3E%3Cstop offset='0.438632' stop-color='%23469BC7'/%3E%3Cstop offset='0.588787' stop-color='%23469FC9'/%3E%3Cstop offset='0.738942' stop-color='%2354A5BE'/%3E%3Cstop offset='0.89587' stop-color='%236FA9A8'/%3E%3Cstop offset='1' stop-color='%2391AE8A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
    }
    span{
    //margin-right: 0.5ch;
      display: inline-flex;
      &[class^='text-']{
        font-weight: 300;
      }
      &[class^='text-']:hover{
        //font-weight: 400;
        //transform: scale(1.02);
      }
    }
    font-weight: 200;
    max-width: 595px;
    font-size: 35px;
    @include media-breakpoint-down(md){
      font-size: 28px;
    }
    @include media-breakpoint-down(sm){
      font-size: 24px;
    }
    margin:auto;
    @extend %font-roboto;
  }
}

.quote__wrapper{
  background-color: $color-white;
  /* Rectangle 341 */

  position: relative;
  //background: linear-gradient(0deg, #e8f8e5 -0.25%, #ffffff 23.06%, #ffffff 75.37%, #e7f4f6 99.82%);

  z-index: -1;
  overflow: hidden;
  &:before{
    z-index: 1;
    content:'';
    position: absolute;
    left:0;
    top:0;
    border-style: solid;
    border-width: 3vw 100vw 0 0;
    border-color: $color-light transparent transparent transparent;
  }

  &:after{
    content:'';
    position: absolute;
    left:0;
    bottom:0;
    z-index: 1;

    border-style: solid;
    border-width: 0vw 0 3vw 100vw;
    border-color: transparent transparent $color-light transparent;
  }
}

canvas{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  //z-index: -1;
  //z-index: 0;
}
.pre__footer{
  svg{
    margin-bottom: -2px;
    vertical-align: bottom;
  }
}
.footer{

  /* pysport */
  background: linear-gradient(90.41deg, #4D97CA 0.42%, #469BC7 45.58%, #469FC9 61.04%, #54A5BE 76.51%, #6FA9A8 92.66%, #91AE8A 103.39%);
  color: $color-white;
  padding-bottom: 12rem;

 a{
    color: $color-white;
  }
}

.social-icons svg *{
  //fill: white;
  stroke: white;
}

.main__heading{
  margin: 2rem auto;
  @extend %font-display;
  color: $color-blue;
}

.text-content{

  line-height: 1.5;;
  p+p{
    margin-top: 2rem;
  }
}